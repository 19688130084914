import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Loader from '../components/loader';
import NavigationBar from '../components/navigationBar';
import { ROUTES as _routes } from '../constants/app-constants';
import LocalStorage from '../utils/service';

const LoginPage = lazy(() => import('../pages/loginPage'));
const HomePage = lazy(() => import('../pages/homePage'));
const BkVideosPage = lazy(() => import('../pages/bkVideos'));
const CreateVediooPage = lazy(() => import('../pages/createVideos'));
const BannerImageUploadPage = lazy(() =>
  import('../pages/bannerImageUploadPage')
);
const EditBannerDetailsPage = lazy(() =>
  import('../pages/editBannerDetailsPage')
);
const CreateNewBannerPage = lazy(() => import('../pages/createNewBannerPage'));
const HomeSectionsPage = lazy(() => import('../pages/homeSectionsPage'));
const CreateNewSectionPage = lazy(() =>
  import('../pages/createNewSectionPage')
);
const EditSectionPage = lazy(() => import('../pages/editSectionPage'));
const ProductPromoPage = lazy(() => import('../pages/productPromoPage'));
const CreateProductPromoPage = lazy(() =>
  import('../pages/createProductPromoPage')
);
const EditProductPromoPage = lazy(() =>
  import('../pages/editProductPromoPage')
);
const ProductImageUploadPage = lazy(() =>
  import('../pages/productImageUploadPage')
);
const UploadNewProductImagePage = lazy(() =>
  import('../pages/uploadNewProductImagePage')
);
const EditProductImagePage = lazy(() =>
  import('../pages/editProductImagePage')
);
const PushNotificationPage = lazy(() =>
  import('../pages/pushNotificationPage')
);
const CreateVideos = lazy(() => import('../pages/createVideos'));
const OfferPage = lazy(() => import('../pages/offerPage'));
const DynamicOrder = lazy(() => import('../pages/dynamicOrderPage'));
const DeepLink = lazy(() => import('../pages/deepLinkPage'));
const ListOfDeepLinks = lazy(() => import('../pages/listofDeepLinksPage'));
const CreateOfferPage = lazy(() => import('../pages/createOfferPage'));
const CheckoutModePage = lazy(() => import('../pages/checkoutModePage'));
const ChargesPage = lazy(() => import('../pages/chargesUpdate'));
const EmpOffers = lazy(() => import('../nPages/emp0ffers'));
const OpenSearch = lazy(() => import('../nPages/openSearch'));
const CacheDurationPage = lazy(() => import('../pages/cacheDuration'));
const LinkOfferPage = lazy(() => import('../pages/linkOfferPage'));
const ProductCategoryPage = lazy(() => import('../pages/productCategoryPage'));
const BlockCodPage = lazy(() => import('../pages/blockCOD'));
const BlockUserPage = lazy(() => import('../pages/addblockUser'));
const EditProductCategoryPage = lazy(() =>
  import('../pages/editProductCategoryPage')
);
const EditProductCategoryTimePage = lazy(() =>
  import('../pages/editProductCategoryTimePage')
);
const ComboProductPage = lazy(() => import('../pages/comboProductPage'));
const CreateComboProductPage = lazy(() =>
  import('../pages/createComboProductPage')
);
const EditComboProductPage = lazy(() =>
  import('../pages/editComboProductPage')
);
const CreateProductPage = lazy(() => import('../pages/createProductPage'));
const EditLinkedOfferPage = lazy(() => import('../pages/editLinkedOfferPage'));
const OrderDetailsPage = lazy(() => import('../pages/orderDetailsPage'));
const OutletPage = lazy(() => import('../pages/outletPage'));
const ParticularOrderDetailsPage = lazy(() =>
  import('../pages/particularOrderPage')
);
const CreateOutletPage = lazy(() => import('../pages/createOutletPage'));
const CreateUsersPage = lazy(() => import('../pages/createUsers'));
const UsersPage = lazy(() => import('../pages/users'));
const ReportPage = lazy(() => import('../pages/reportPage'));
const FeedbackPage = lazy(() => import('../pages/feedbackPage'));
const InvestorTreePage = lazy(() => import('../pages/investorTreepage'));
const InvestorPage = lazy(() => import('../pages/investorPage'));
const CreateAndEditInvestorPage = lazy(() =>
  import('../pages/createAndEditInvestorPage')
);
const CreateAndEditInvestorTreePage = lazy(() =>
  import('../pages/createAndEditInvestorTreePage')
);
const CreateDynamicOrderPage = lazy(() =>
  import('../pages/createDynamicOrderPage')
);
const EditDynamicOrderPage = lazy(() =>
  import('../pages/createDynamicOrderPage')
);
const CSVUploadPage = lazy(() => import('../pages/uploadAttachmentsPage'));
const GamificationPage = lazy(() => import('../pages/gamifications'));
const CustomizationListPage = lazy(() =>
  import('../pages/customizationListPage')
);
const CustomizationPage = lazy(() => import('../pages/customizationPage'));
const BankOffersCreateandEditPage = lazy(() =>
  import('../pages/bankOffersCreateandEditPage')
);
const BankOffersPage = lazy(() => import('../pages/bankOffersPage'));
const AppearanceListPage = lazy(() => import('../pages/appearanceListPage'));
const CategoryTagsListPage = lazy(() =>
  import('../pages/categoryTagsListPage')
);
const CreateTagsPage = lazy(() => import('../pages/createCategoryTags'));
const NutritionsListPage = lazy(() =>
  import('../pages/productNutritionListPage')
);
const ProductNutrition = lazy(() => import('../pages/manageNutritionPage'));
const KingsClubOfferList = lazy(() =>
  import('../pages/kingsClubOfferListPage')
);
const ManageClubOffer = lazy(() => import('../pages/manageClubOfferPage'));
const CreateProductOfferPage = lazy(() =>
  import('../pages/createProductOfferPage')
);
const EditProductOfferPage = lazy(() =>
  import('../pages/editProductOfferPage')
);

// nPages
const Login = lazy(() => import('../nPages/login'));
const OfferListing = lazy(() => import('../nPages/offersList'));
const ManageOffers = lazy(() => import('../nPages/manageOffers'));
const EditCrownOffers = lazy(() => import('../nPages/crownOffersEdit'));

const BankOffersList = lazy(() => import('../nPages/bankOffers'));
const ManageBankOffers = lazy(() => import('../nPages/manageBankOffers'));

const ClubOfferList = lazy(() => import('../nPages/clubOffers'));
const ManageClubOffers = lazy(() => import('../nPages/manageClubOffer'));

const CategoryTags = lazy(() => import('../nPages/categoryTagsList'));
const ManageCategoryTags = lazy(() => import('../nPages/manageCategoryTags'));

const NutritionData = lazy(() => import('../nPages/productNutritionList'));
const ManageNutritionData = lazy(() => import('../nPages/manageNutrition'));

const OutletListing = lazy(() => import('../nPages/outletList'));
const ManageOutlets = lazy(() => import('../nPages/manageOutlet'));

const OrderListing = lazy(() => import('../nPages/orderList'));
const OrderSummary = lazy(() => import('../nPages/orderSummary'));

// nPages Customization
const CardSeparation = lazy(() => import('../nPages/cardSeparation'));
const AddonsSeparation = lazy(() => import('../nPages/addonsSeparation'));
const ManageCustomization = lazy(() => import('../nPages/manageProducts'));

const Router = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const getLoginStatus = () => {
      if (
        LocalStorage.getLoginDetails('IS_LOGGED_IN') &&
        location.pathname === _routes.LOGIN
      ) {
        history.replace(_routes.HOME);
      } else if (
        LocalStorage.getLoginDetails('IS_LOGGED_IN') === null &&
        location.pathname !== _routes.LOGIN
      ) {
        history.replace(_routes.LOGIN);
      }
    };

    getLoginStatus();
  }, [history, location.pathname]);

  const getPath = () => {
    return location.pathname;
  };

  return (
    <div className="bg-light h-100">
      <Suspense fallback={<Loader />}>
        {getPath() !== _routes.LOGIN && (
          <NavigationBar currentPath={getPath()} />
        )}
        <Switch>
          <Route exact path={_routes.LOGIN} component={Login} />
          <Route exact path={_routes.HOME} component={HomePage} />
          <Route exact path={_routes.BK_VIDEO} component={BkVideosPage} />
          <Route
            exact
            path={_routes.CREATE_VIDEO}
            component={CreateVediooPage}
          />
          <Route
            exact
            path={_routes.BANNERS}
            component={BannerImageUploadPage}
          />
          <Route
            exact
            path={_routes.EDIT_BANNERS}
            component={EditBannerDetailsPage}
          />
          <Route
            exact
            path={_routes.CREATE_NEW_BANNER}
            component={CreateNewBannerPage}
          />
          <Route
            exact
            path={_routes.HOME_SECTIONS}
            component={HomeSectionsPage}
          />
          <Route
            exact
            path={_routes.CREATE_NEW_SECTION}
            component={CreateNewSectionPage}
          />
          <Route
            exact
            path={_routes.EDIT_SECTION}
            component={EditSectionPage}
          />
          <Route
            exact
            path={_routes.PRODUCT_PROMO}
            component={ProductPromoPage}
          />
          <Route
            exact
            path={_routes.CREATE_PRODUCT_PROMO}
            component={CreateProductPromoPage}
          />
          <Route
            exact
            path={_routes.EDIT_PRODUCT_PROMO}
            component={EditProductPromoPage}
          />
          <Route
            exact
            path={_routes.PRODUCT_IMAGE_UPLOAD}
            component={ProductImageUploadPage}
          />
          <Route
            exact
            path={_routes.UPLOAD_NEW_PRODUCT}
            component={UploadNewProductImagePage}
          />
          <Route
            exact
            path={_routes.EDIT_PRODUCT_IMAGE}
            component={EditProductImagePage}
          />
          <Route
            exact
            path={_routes.PUSH_NOTIFICATION}
            component={PushNotificationPage}
          />
          <Route exact path={_routes.DYNAMIC_ORDER} component={DynamicOrder} />
          <Route exact path={_routes.CREATE_DEEP_LINK} component={DeepLink} />
          <Route
            exact
            path={_routes.LIST_OF_DEEPLINKS}
            component={ListOfDeepLinks}
          />
          <Route
            exact
            path={_routes.CREATE_OFFER}
            component={CreateOfferPage}
          />
          <Route
            exact
            path={_routes.CHECKOUT_MODE}
            component={CheckoutModePage}
          />
          <Route exact path={_routes.CHARGES} component={ChargesPage} />
          <Route exact path={_routes.EMP_OFFERS} component={EmpOffers} />
          <Route exact path={_routes.OPEN_SEARCH} component={OpenSearch} />

          <Route
            exact
            path={_routes.CACHE_DURATION}
            component={CacheDurationPage}
          />
          <Route exact path={_routes.LINK_OFFER} component={LinkOfferPage} />
          <Route
            exact
            path={_routes.PRODUCT_CATEGORY}
            component={ProductCategoryPage}
          />
          {/* <Route
            exact
            path={_routes.EDIT_LINK_OFFER}
            component={EditLinkedOfferPage}
          /> */}
          <Route
            exact
            path={_routes.CREATE_DYNAMIC_ORDER}
            component={CreateDynamicOrderPage}
          />
          <Route
            exact
            path={_routes.EDIT_DYNAMIC_ORDER}
            component={EditDynamicOrderPage}
          />
          <Route
            exact
            path={_routes.EDIT_PRODUCT_CATEGORY}
            component={EditProductCategoryPage}
          />
          <Route
            exact
            path={_routes.EDIT_PRODUCT_CATEGORY_TIME}
            component={EditProductCategoryTimePage}
          />
          <Route
            exact
            path={_routes.COMBO_PRODUCT}
            component={ComboProductPage}
          />
          <Route
            exact
            path={_routes.CREATE_COMBO_PRODUCT}
            component={CreateComboProductPage}
          />
          <Route
            exact
            path={_routes.EDIT_COMBO_PRODUCT}
            component={EditComboProductPage}
          />
          <Route
            exact
            path={_routes.CREATE_PRODUCT}
            component={CreateProductPage}
          />
          <Route
            exact
            path={_routes.PARTICULAR_ORDER_DETAILS}
            component={ParticularOrderDetailsPage}
          />
          <Route
            exact
            path={_routes.ORDER_DETAILS}
            component={OrderDetailsPage}
          />
          {/* <Route exact path={_routes.OUTLET} component={OutletPage} /> */}
          {/* <Route
            exact
            path={_routes.CREATE_OUTLET}
            component={CreateOutletPage}
          /> */}
          <Route exact path={_routes.USERS} component={UsersPage} />
          <Route
            exact
            path={_routes.CREATE_USERS}
            component={CreateUsersPage}
          />
          <Route path={_routes.REPORT} component={ReportPage} />
          <Route exact path={_routes.FEEDBACK} component={FeedbackPage} />
          <Route
            exact
            path={_routes.INVESTOR_TREE}
            component={InvestorTreePage}
          />
          <Route exact path={_routes.INVESTOR} component={InvestorPage} />
          <Route exact path={_routes.BLOCK_COD} component={BlockCodPage} />
          <Route exact path={_routes.BLOCK_USER} component={BlockUserPage} />
          <Route
            exact
            path={_routes.CREATE_INVESTORID}
            component={CreateAndEditInvestorPage}
          />
          <Route
            exact
            path={_routes.CREATE_INVESTOR_TREE}
            component={CreateAndEditInvestorTreePage}
          />
          <Route
            exact
            path={_routes.EDIT_INVESTORID}
            component={CreateAndEditInvestorPage}
          />
          <Route
            exact
            path={_routes.EDIT_INVESTOR_TREE}
            component={CreateAndEditInvestorTreePage}
          />
          <Route exact path={_routes.CREATE_VIDEO} component={CreateVideos} />
          <Route exact path={_routes.BK_VIDEO} component={BkVideosPage} />
          <Route
            exact
            path={_routes.GAMIFICATION}
            component={GamificationPage}
          />
          {/* <Route
            exact
            path={_routes.CREATE_CUSTOMIZATION}
            component={CustomizationPage}
          />
          <Route
            exact
            path={`${_routes.EDIT_CUSTOMIZATION}/:product_id`}
            component={CustomizationPage}
          />
          <Route
            exact
            path={_routes.CREATE_CARD_CUSTOMIZATION}
            component={CustomizationPage}
          />
          <Route
            exact
            path={_routes.EDIT_CARD_CUSTOMIZATION}
            component={CustomizationPage}
          /> */}
          {/* <Route exact path={_routes.BANK_OFFERS} component={BankOffersPage} />
          <Route
            exact
            path={_routes.CREATE_BANK_OFFERS}
            component={BankOffersCreateandEditPage}
          /> */}
          {/* <Route
            exact
            path={_routes.CATEGORY_TAGS}
            component={CategoryTagsListPage}
          /> */}
          {/* <Route
            exact
            path={_routes.CREATE_CATEGORY_TAGS}
            component={CreateTagsPage}
          />
          <Route
            exact
            path={_routes.EDIT_CATEGORY_TAGS}
            component={CreateTagsPage}
          /> */}
          {/* <Route
            exact
            path={_routes.NUTRITION_INFO}
            component={NutritionsListPage}
          />
          <Route
            exact
            path={_routes.CREATE_NUTRITION}
            component={ProductNutrition}
          />
          <Route
            exact
            path={_routes.EDIT_NUTRITION}
            component={ProductNutrition}
          /> */}
          {/* <Route
            exact
            path={_routes.KINGS_CLUB_OFFER}
            component={KingsClubOfferList}
          />
          <Route
            exact
            path={_routes.CREATE_KINGS_CLUB_OFFER}
            component={ManageClubOffer}
          />
          <Route
            exact
            path={_routes.EDIT_KINGS_CLUB_OFFER}
            component={ManageClubOffer}
          /> */}
          <Route
            exact
            path={_routes.OFFER_ATTACHMENT}
            component={CSVUploadPage}
          />
          {/* <Route
            exact
            path={_routes.CREATE_PRODUT_OFFER}
            component={CreateProductOfferPage}
          />
          <Route
            exact
            path={_routes.EDIT_PRODUCT_OFFER}
            component={EditProductOfferPage}
          /> */}

          {/* nPages Routes */}
          <Route exact path={_routes.OFFERS} component={OfferListing} />
          <Route
            exact
            path={_routes.CREATE_PRODUT_OFFER}
            component={ManageOffers}
          />
          <Route
            exact
            path={_routes.EDIT_PRODUCT_OFFER}
            component={ManageOffers}
          />
          <Route
            exact
            path={_routes.EDIT_LINK_OFFER}
            component={EditCrownOffers}
          />
          {/* ADDONS SEPARATION */}
          <Route
            exact
            path={_routes.ADDONS_SEPARATION}
            component={AddonsSeparation}
          />
          <Route
            exact
            path={_routes.CREATE_CUSTOMIZATION}
            component={ManageCustomization}
          />
          <Route
            exact
            path={`${_routes.EDIT_CUSTOMIZATION}/:product_id`}
            component={ManageCustomization}
          />
          {/* CARD SEPARATION */}
          <Route
            exact
            path={_routes.CARD_SEPARATION}
            component={CardSeparation}
          />
          <Route
            exact
            path={_routes.CREATE_CARD_CUSTOMIZATION}
            component={ManageCustomization}
          />
          <Route
            exact
            path={`${_routes.EDIT_CARD_CUSTOMIZATION}/:product_id`}
            component={ManageCustomization}
          />
          {/* BANK OFFERS */}
          <Route exact path={_routes.BANK_OFFERS} component={BankOffersList} />
          <Route
            exact
            path={_routes.CREATE_BANK_OFFERS}
            component={ManageBankOffers}
          />
          {/*CATEGORY TAGS*/}
          <Route exact path={_routes.CATEGORY_TAGS} component={CategoryTags} />
          <Route
            exact
            path={_routes.CREATE_CATEGORY_TAGS}
            component={ManageCategoryTags}
          />
          <Route
            exact
            path={_routes.EDIT_CATEGORY_TAGS}
            component={ManageCategoryTags}
          />
          {/* NUTRITION INFO */}
          <Route
            exact
            path={_routes.NUTRITION_INFO}
            component={NutritionData}
          />
          <Route
            exact
            path={_routes.CREATE_NUTRITION}
            component={ManageNutritionData}
          />
          <Route
            exact
            path={_routes.EDIT_NUTRITION}
            component={ManageNutritionData}
          />

          {/* KING'S CLUB OFFERS */}
          <Route
            exact
            path={_routes.KINGS_CLUB_OFFER}
            component={ClubOfferList}
          />
          <Route
            exact
            path={_routes.CREATE_KINGS_CLUB_OFFER}
            component={ManageClubOffers}
          />
          <Route
            exact
            path={_routes.EDIT_KINGS_CLUB_OFFER}
            component={ManageClubOffers}
          />

          {/*OUTLET*/}
          <Route exact path={_routes.OUTLET} component={OutletListing} />
          <Route exact path={_routes.CREATE_OUTLET} component={ManageOutlets} />
          <Route exact path={_routes.EDIT_OUTLET} component={ManageOutlets} />

          {/*ORDERS*/}
          {/* <Route exact path={_routes.ORDER_DETAILS} component={OrderListing} />
          <Route
            exact
            path={_routes.PARTICULAR_ORDER_DETAILS}
            component={OrderSummary}
          /> */}
        </Switch>
      </Suspense>
    </div>
  );
};

export default Router;
