/* eslint-disable camelcase */
import React from 'react';
import { Dropdown, Image, Nav, Navbar } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Avatar from '../../../assets/images/Avatar.png';
import Logo from '../../../assets/images/bk-logo.png';
import { ACCESS, ROUTES as _routes } from '../../constants/app-constants';
import LocalStorage from '../../utils/service';

class Navigationbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: LocalStorage.getAdmin('IS_ADMIN') || ACCESS.ADMIN,
      access: LocalStorage.getData('ACCESS') || ACCESS.MODULES,
      count: this.calculateCount(window?.innerWidth)
    };
    this.onLogoutClick = this.onLogoutClick.bind(this);
  }

  onLogoutClick() {
    LocalStorage.clearData();
    const logout = this.props;
    logout.history.push(_routes.LOGIN);
  }

  onNavigationClick = route => {
    const { history } = this.props;
    history.push(route);
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ count: this.calculateCount(window.innerWidth) });
  };

  calculateCount = innerWidth => {
    if (innerWidth === 1280) {
      return 12;
    } else if (innerWidth === 1600) {
      return 14;
    } else if (innerWidth === 1920) {
      return 16;
    } else {
      return 16;
    }
  };

  render() {
    let mainRoutes = [];
    let moreRoutes = [];
    const { access, isAdmin } = this.state;
    const { currentPath } = this.props;
    let Admin = LocalStorage.getAdmin('IS_ADMIN');
    if (Admin === undefined || null) {
      Admin = isAdmin;
    }
    const {
      is_banners,
      is_category,
      is_home,
      is_offers,
      is_orders,
      is_outlets,
      is_product,
      // is_promos,
      is_reports,
      is_feedbacks,
      is_codBlock,
      is_bkvideos,
      is_investors,
      is_dynamicOrders,
      is_deeplinks,
      is_checkoutMode,
      is_charges,
      is_empOffers,
      is_openSearch,
      is_cacheDuration,
      is_gamification,
      is_bankOffers,
      is_addonSeparation,
      is_cardSeparation,
      is_categoryTags,
      is_nutritionInfo,
      is_kingClubOffer
    } = access?.access_to;
    const navItems = [
      {
        key: 'banners',
        label: 'Banners',

        route: _routes.HOME,
        isVisible: is_banners
      },
      {
        key: 'homeSections',
        label: 'Home Sections',

        route: _routes.HOME_SECTIONS,
        isVisible: is_home
      },
      // Add other navigation items here with their respective details
      // Example: { key: 'promos', label: 'Product Promo',   route: _routes.PRODUCT_PROMO, isVisible: is_promos },
      {
        key: 'offers',
        label: 'Offers',

        route: _routes.OFFERS,
        isVisible: is_offers
      },
      {
        key: 'category',
        label: 'Product Category',

        route: _routes.PRODUCT_CATEGORY,
        isVisible: is_category
      },
      {
        key: 'product',
        label: 'Product Image Upload',

        route: _routes.PRODUCT_IMAGE_UPLOAD,
        isVisible: is_product
      },
      {
        key: 'orders',
        label: 'Orders',

        route: _routes.ORDER_DETAILS,
        isVisible: is_orders === true
      },
      {
        key: 'outlets',
        label: 'Outlet',

        route: _routes.OUTLET,
        isVisible: is_outlets === true
      },
      {
        key: 'reports',
        label: 'Report',

        route: _routes.REPORT,
        isVisible: is_reports === true
      },
      {
        key: 'users',
        label: 'Users',

        route: _routes.USERS,
        isVisible: Admin
      },
      {
        key: 'feedback',
        label: 'Feedback',

        route: _routes.FEEDBACK,
        isVisible: is_feedbacks === true
      },
      {
        key: 'investor',
        label: 'Investor',
        // onClick: this.onInvestorClick,
        route: _routes.INVESTOR_TREE,
        isVisible: Admin || is_investors
      },
      {
        key: 'codBlock',
        label: 'User Management',
        // onClick: this.onBlockUserClick,
        route: _routes.BLOCK_COD,
        isVisible: is_codBlock === true
      },
      {
        key: 'bkvideos',
        label: 'BK Videos',
        // onClick: this.onBkVideosClick,
        route: _routes.BK_VIDEO,
        isVisible: is_bkvideos === true
      },
      {
        key: 'dynamicOrders',
        label: 'Dynamic Orders',
        // onClick: this.onDynamicOrderClick,
        route: _routes.DYNAMIC_ORDER,
        isVisible: is_dynamicOrders === true
      },
      {
        key: 'deeplinks',
        label: 'Deep Links',
        // onClick: this.onDeepLinkClick,
        route: _routes.LIST_OF_DEEPLINKS,
        isVisible: is_deeplinks === true
      },
      {
        key: 'charges',
        label: 'Charges',

        route: _routes.CHARGES,
        isVisible: is_charges
      },
      {
        key: 'gamification',
        label: 'Gamification',

        isVisible: is_gamification,
        route: _routes.GAMIFICATION
      },
      {
        key: 'checkoutMode',
        label: 'Checkout Mode',

        isVisible: is_checkoutMode,
        route: _routes.CHECKOUT_MODE
      },
      {
        key: 'cacheDuration',
        label: 'Cache Duration',

        isVisible: is_cacheDuration,
        route: _routes.CACHE_DURATION
      },
      {
        key: 'addonSeparation',
        label: 'Addon Separation',

        isVisible: is_addonSeparation,
        route: _routes.ADDONS_SEPARATION
      },
      {
        key: 'cardSeparation',
        label: 'Card Separation',

        isVisible: is_cardSeparation,
        route: _routes.CARD_SEPARATION
      },
      {
        key: 'bankOffers',
        label: 'Bank Offers',
        isVisible: is_bankOffers,
        route: _routes.BANK_OFFERS
      },
      {
        key: 'categoryTags',
        label: 'Category Tags',
        isVisible: is_categoryTags,
        route: _routes.CATEGORY_TAGS
      },
      {
        key: 'nutrition_info',
        label: 'Nutrition Info',
        isVisible: is_nutritionInfo,
        route: _routes.NUTRITION_INFO
      },
      {
        key: 'kings_club',
        label: "King's Club Offer",
        isVisible: is_kingClubOffer,
        route: _routes.KINGS_CLUB_OFFER
      },
      {
        key: 'empOffers',
        label: 'Emp Offers',

        route: _routes.EMP_OFFERS,
        isVisible: is_empOffers
      },
      {
        key: 'openSearch',
        label: 'Open Search',

        route: _routes.OPEN_SEARCH,
        isVisible: is_openSearch
      }
    ];
    const navCount = Object.values(access?.access_to).filter(Boolean).length;
    const { count } = this.state;
    let navItems1 = navItems.filter(item => item.isVisible);
    if (navCount > count) {
      mainRoutes = navItems1.slice(0, count);
      moreRoutes = navItems1.slice(count);
    } else {
      mainRoutes = navItems1;
    }
    const Dom = (
      <Navbar bg="dark" variant="dark" sticky="top">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Brand className="u_margin_right_0">
          <Image
            src={Logo}
            width={60}
            height={60}
            //onClick={this.onBannersClick}
            className="u_cursor_pointer"
          />
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {mainRoutes.map(
              item =>
                item.isVisible && (
                  <Nav.Link
                    key={item.key}
                    onClick={() => this.onNavigationClick(item.route)}
                    className={
                      currentPath.indexOf(item.route) >= 0 ? 'active' : ''
                    }
                  >
                    {item.label}
                  </Nav.Link>
                )
            )}
          </Nav>
          {moreRoutes.length > 0 && (
            <Dropdown>
              <Dropdown.Toggle variant="dark" id="dropdown-basic">
                More
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {moreRoutes?.map(
                  item =>
                    item.isVisible && (
                      <Dropdown.Item
                        key={item.key}
                        onClick={() => this.onNavigationClick(item.route)}
                      >
                        {item.label}
                      </Dropdown.Item>
                    )
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
          <Navbar.Brand>
            <Dropdown alignRight>
              <Dropdown.Toggle as="div" id="profile-dropdown">
                <Image
                  src={Avatar}
                  width={40}
                  height={40}
                  className="u_cursor_pointer"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu as="div">
                <Dropdown.Item onClick={this.onLogoutClick}>
                  LOG OUT
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Navbar.Brand>
        </Navbar.Collapse>
      </Navbar>
    );
    return Dom;
  }
}

export default withRouter(Navigationbar);
