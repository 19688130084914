import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Loader = () => {
  const Dom = (
    <div className="d-flex justify-content-center h-100 align-items-center">
      <Spinner animation="border" role="status" className="u_text_primary">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
  return Dom;
};

export default Loader;
