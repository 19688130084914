import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-dropzone-uploader/dist/styles.css';

import Router from './app/router';
import './App.scss';

function App() {
  return (
    <BrowserRouter>
      <Route path="/" component={Router} />
    </BrowserRouter>
  );
}

export default App;
