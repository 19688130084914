const setValueToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const getValueFromLocalStorage = key => {
  if (localStorage.getItem(key) !== 'undefined')
    return JSON.parse(localStorage.getItem(key));
  return null;
};

const setLoginDetails = value => {
  setValueToLocalStorage('IS_LOGGED_IN', value);
};

const getLoginDetails = key => {
  return getValueFromLocalStorage(key);
};

const setAdmin = value => {
  setValueToLocalStorage('IS_ADMIN', value);
};

const getAdmin = key => {
  return getValueFromLocalStorage(key);
};

const clearLocalStorage = () => {
  localStorage.clear();
};

const LocalStorage = {
  getLoginDetails,
  setLoginDetails,
  setAdmin,
  getAdmin,
  getData: getValueFromLocalStorage,
  setData: setValueToLocalStorage,
  clearData: clearLocalStorage
};

export default LocalStorage;
